import './SecondaryDomain.scss';

import React from 'react';
import { difference, intersection } from 'lodash';

import * as Core from 'components/core';

import { DomainNode } from './domains';

interface Props {
  domain: DomainNode;
  selectedTertiaryDomainIDs: string[];
  toggleSecondaryDomain: (id: string) => void;
  toggleTertiaryDomain: (id: string) => void;
}

interface State {
  expanded: boolean;
}

/**
 * A list of skills in a group. The group, and each skill, can be toggled with
 * a checkbox, and the group can be expanded or collapsed.
 */
export default class CompareTermsSecondaryDomain extends React.Component<
  Props,
  State
> {
  state: State = {
    expanded: false
  };

  handleGroupChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.toggleSecondaryDomain(event.currentTarget.value);
  };

  handleSkillChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.toggleTertiaryDomain(event.currentTarget.value);
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { domain } = this.props;
    const { expanded } = this.state;

    const groupSkillIDs = domain.children!.map((node) => node.data.identifier);
    const groupSelected =
      difference(groupSkillIDs, this.props.selectedTertiaryDomainIDs).length ===
      0;

    const numberOfSelectedItems = intersection(
      this.props.selectedTertiaryDomainIDs,
      groupSkillIDs
    ).length;

    const groupPartiallySelected = !groupSelected && numberOfSelectedItems > 0;

    return (
      <div className="CompareTermsSecondaryDomain">
        <div className="CompareTermsSecondaryDomain-heading">
          <label className="CompareTermsSecondaryDomain-checkbox">
            <Core.Checkbox
              value={domain.data.identifier}
              checked={groupSelected}
              indeterminate={groupPartiallySelected}
              onChange={this.handleGroupChanged}
              className="CompareTermsSecondaryDomain-checkbox-input"
            />
            <div className="CompareTermsSecondaryDomain-checkbox-label">
              {domain.data.name}
            </div>
            {groupPartiallySelected && (
              <span>&nbsp;({numberOfSelectedItems})</span>
            )}
          </label>
          <button
            className="CompareTermsSecondaryDomain-toggleButton"
            onClick={this.toggleExpanded}
            title={
              this.state.expanded
                ? `Hide ${domain.data.name} subdomains`
                : `Show ${domain.data.name} subdomains`
            }
          >
            {this.state.expanded ? 'Hide' : 'Show'}
          </button>
        </div>
        {expanded &&
          domain.children!.map((node) => (
            <label
              key={node.data.identifier}
              className="CompareTermsSecondaryDomain-checkbox"
            >
              <Core.Checkbox
                value={node.data.identifier}
                checked={this.props.selectedTertiaryDomainIDs.includes(
                  node.data.identifier
                )}
                onChange={this.handleSkillChanged}
                className="CompareTermsSecondaryDomain-checkbox-input"
              />
              <div>{node.data.name}</div>
            </label>
          ))}
      </div>
    );
  }
}
