import './SelectionDetails.scss';

import React from 'react';

interface Props {
  frameworks: {
    identifier: number;
    name: string;
    color: string;
  }[];
  hoveredItem: any | null;
  selectedItem: any | null;
}

/**
 * Content to be displayed in the center of the sunburst diagram about the
 * current hovered / selected items.
 */
const CompareTermsSelectionDetails: React.FC<Props> = (props) => {
  let { hoveredItem } = props;
  const { selectedItem, frameworks } = props;

  if (hoveredItem === selectedItem) {
    hoveredItem = null;
  }

  const frameworkWithID = (id: number) => {
    return frameworks.find((f) => f.identifier === id)!;
  };

  return (
    <div className="CompareTermsSelectionDetails">
      {selectedItem != null && (
        <div>
          <div className="CompareTermsSelectionDetails-framework">
            <div
              className="CompareTermsSelectionDetails-icon"
              style={{
                background: `${frameworkWithID(selectedItem.frameworkID).color}`
              }}
            />
            {frameworkWithID(selectedItem.frameworkID)!.name}
          </div>
          {selectedItem.tier > 1 && (
            <div className="CompareTermsSelectionDetails-term">
              {selectedItem.name}
            </div>
          )}
        </div>
      )}

      {selectedItem == null && hoveredItem == null && (
        <div className="CompareTermsSelectionDetails-placeholder no-print">
          Hover over rings to see framework and term names.
        </div>
      )}

      {selectedItem != null && hoveredItem != null && (
        <div className="CompareTermsSelectionDetails-divider">
          <hr className="CompareTermsSelectionDetails-dividerRule" />

          <div className="CompareTermsSelectionDetails-dividerLabel">
            compare to:
          </div>
          <hr className="CompareTermsSelectionDetails-dividerRule" />
        </div>
      )}

      {hoveredItem != null && (
        <div>
          <div className="CompareTermsSelectionDetails-framework">
            <div
              className="CompareTermsSelectionDetails-icon"
              style={{
                background: `${frameworkWithID(hoveredItem.frameworkID).color}`
              }}
            />
            {frameworkWithID(hoveredItem.frameworkID)!.name}
          </div>
          {hoveredItem.tier > 1 && (
            <div className="CompareTermsSelectionDetails-term">
              {hoveredItem.name}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CompareTermsSelectionDetails;
