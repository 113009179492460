import './compare-terms.scss';

import React from 'react';
import { graphql } from 'gatsby';
import { sortBy } from 'lodash';

import { Framework } from 'types';

import Layout from 'components/layout/Layout';
import CompareTermsVisualization from 'components/compare-terms/CompareTermsVisualization';
import VisualToolLinks from 'components/VisualToolLinks';

interface Props {
  data: {
    allFrameworks: {
      frameworks: Framework[];
    };
  };
}

const CompareTermsPage: React.FC<Props> = (props) => {
  const frameworks = sortBy(props.data.allFrameworks.frameworks, (f) => f.name);

  return (
    <Layout>
      <div className="CompareTermsPage-intro">
        <h1>Identify Related Skills Across Frameworks</h1>
        <p className="emphasis">
          Frameworks label skills very differently. It can be difficult to
          figure out where inside a framework the skill you are interested in
          lives – especially if it is labeled or grouped under an unfamiliar
          name.
        </p>
        <p className="emphasis">
          Use the sunburst diagram below to see where a particular skill appears
          across frameworks. Click on a skill in the sunburst or select from a
          list of 100+ common skills to highlight related skills across
          frameworks.
        </p>
      </div>

      <CompareTermsVisualization frameworks={frameworks} />

      <VisualToolLinks excludeVisualToolLink="terms" />
    </Layout>
  );
};

export default CompareTermsPage;

export const query = graphql`
  query CompareTermsQuery {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        name
        color
        # filterAttributes {
        #   developerType
        #   documentType
        #   focus
        #   ageRange
        #   language
        #   setting
        #   regionOfOrigin
        #   countryOfOrigin
        #   regionOfUse
        #   countryOfUse
        #   countryOfOriginIncomeLevel
        #   countryOfUseIncomeLevel
        # }
      }
    }
  }
`;
