import './Printable.scss';

import React, { useCallback } from 'react';

import { domainHierarchy, DomainNode } from './domains';
import classNames from 'classnames';
import { intersection } from 'lodash';

interface Props {
  selectedTertiaryDomainIDs: string[];
}

const Printable: React.FC<Props> = (props) => {
  const { selectedTertiaryDomainIDs } = props;

  const isSelected = useCallback(
    (node: DomainNode) => {
      return (
        selectedTertiaryDomainIDs.includes(node.id!) ||
        node.find((descendant) =>
          selectedTertiaryDomainIDs.includes(descendant.id!)
        )
      );
    },
    [selectedTertiaryDomainIDs]
  );

  const selectedCount = useCallback(
    (node: DomainNode) => {
      if (node.depth === 3) {
        return selectedTertiaryDomainIDs.includes(node.id!);
      }

      return intersection(
        node.leaves().map((leaf) => leaf.id),
        selectedTertiaryDomainIDs
      ).length;
    },
    [selectedTertiaryDomainIDs]
  );

  if (selectedTertiaryDomainIDs.length === 0) {
    return null;
  }

  return (
    <div className="CompareTermsDomainSelectorPrintable print-only">
      <h3 className="CompareTermsDomainSelectorPrintable">Selected Terms</h3>

      <div className="CompareTermsDomainSelectorPrintable-domains">
        {domainHierarchy().children?.map((domain) => (
          <div
            key={domain.data.identifier}
            className="CompareTermsDomainSelectorPrintable-domain"
          >
            <div
              className={classNames(
                'CompareTermsDomainSelectorPrintable-name',
                {
                  'is-selected': isSelected(domain)
                }
              )}
            >
              {domain.data.name} ({selectedCount(domain)} Selected)
            </div>

            <div>
              {domain.children?.map((subdomain) => (
                <div
                  key={subdomain.data.identifier}
                  className="CompareTermsDomainSelectorPrintable-subDomain"
                >
                  <div
                    className={classNames(
                      'CompareTermsDomainSelectorPrintable-name',
                      {
                        'is-selected': isSelected(subdomain)
                      }
                    )}
                  >
                    {subdomain.data.name} ({selectedCount(subdomain)} Selected)
                  </div>

                  {subdomain.children?.map((tertiary) => (
                    <div
                      key={tertiary.data.identifier}
                      className="CompareTermsDomainSelectorPrintable-subDomain"
                    >
                      <div
                        className={classNames(
                          'CompareTermsDomainSelectorPrintable-name',
                          {
                            'is-selected': isSelected(tertiary)
                          }
                        )}
                      >
                        {tertiary.data.name}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Printable;
