import { stratify, HierarchyNode } from 'd3-hierarchy';

import domains from 'data/domains.json';

export interface Domain {
  identifier: string;
  name: string;
  tier: number;
  parent: string | null;
}

export type DomainNode = HierarchyNode<Domain>;

let memo: HierarchyNode<Domain> | null;

export function domainHierarchy() {
  if (memo == null) {
    const root = {
      identifier: 'root',
      name: 'Domains',
      tier: -1,
      parent: null
    };

    memo = stratify<Domain>()
      .id((d) => d.identifier)
      .parentId((d) => (d.tier === 0 ? 'root' : d.parent))(
      domains.concat([root])
    );
  }

  return memo;
}
