import './DomainSelector.scss';

import React from 'react';
import classNames from 'classnames';
import { flatten, difference, union, without, intersection } from 'lodash';

import { arrayTogglingElement } from 'utils/collections';

import SecondaryDomain from './SecondaryDomain';

import { domainHierarchy, DomainNode } from './domains';

interface Props {
  selectedTertiaryDomainIDs: string[];
  setSelectedTertiaryDomainIDs(ids: string[]): void;
}

interface State {
  selectedTopLevelDomainID: string;
}

export default class CompareTermsDomainSelector extends React.Component<
  Props,
  State
> {
  state: State = {
    selectedTopLevelDomainID: domainHierarchy().children![0].data.identifier
  };

  get selectedCategory(): DomainNode | null {
    const category = domainHierarchy().children!.find(
      (node) => node.data.identifier === this.state.selectedTopLevelDomainID
    );

    if (category == null) {
      throw 'CompareTermsDomainSelector must have a valid selectedTopLevelDomainID';
    }
    return category;
  }

  isSecondaryDomainSelected(id: string): boolean {
    const { selectedTertiaryDomainIDs } = this.props;
    const secondaryDomain = this.secondaryDomainWithID(id);
    if (secondaryDomain == null) {
      return false;
    }
    const tertiaryDomainIDs = secondaryDomain.children!.map(
      (node) => node.data.identifier
    );
    return (
      difference(tertiaryDomainIDs, selectedTertiaryDomainIDs).length === 0
    );
  }

  reset = () => {
    this.props.setSelectedTertiaryDomainIDs([]);
  };

  selectTopLevelDomain = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    this.setState({ selectedTopLevelDomainID: event.currentTarget.value });
  };

  secondaryDomainWithID(id: string) {
    return flatten(
      domainHierarchy().children!.map((node) => node.children!)
    ).find((node) => node.data.identifier === id);
  }

  toggleSecondaryDomain = (id: string) => {
    const tertiaryDomainIDs = this.secondaryDomainWithID(id)!.children!.map(
      (node) => node.data.identifier
    );

    this.props.setSelectedTertiaryDomainIDs(
      this.isSecondaryDomainSelected(id)
        ? without(this.props.selectedTertiaryDomainIDs, ...tertiaryDomainIDs)
        : union(this.props.selectedTertiaryDomainIDs, tertiaryDomainIDs)
    );
  };

  toggleTertiaryDomain = (id: string) => {
    this.props.setSelectedTertiaryDomainIDs(
      arrayTogglingElement(this.props.selectedTertiaryDomainIDs, id)
    );
  };

  selectedNumberInTopLevelDomain(category: DomainNode): number {
    const tertiaryDomainIDs = flatten(
      flatten(category.children!.map((node) => node.children!)).map(
        (node) => node.data.identifier
      )
    );

    return intersection(tertiaryDomainIDs, this.props.selectedTertiaryDomainIDs)
      .length;
  }

  render() {
    const { selectedTertiaryDomainIDs } = this.props;
    const selectedCategory = this.selectedCategory;

    return (
      <>
        <div className="CompareTermsDomainSelector-header">
          <h4 className="CompareTermsDomainSelector-headingLabel">
            Filter by Domain
          </h4>
          <button
            className="CompareTermsDomainSelector-resetButton"
            onClick={this.reset}
            disabled={selectedTertiaryDomainIDs.length === 0}
          >
            Reset
          </button>
        </div>

        <div>
          <div className="CompareTermsDomainSelector-categories">
            {domainHierarchy().children!.map((node) => {
              const selectedSkills = this.selectedNumberInTopLevelDomain(node);

              return (
                <button
                  className={classNames(
                    'CompareTermsDomainSelector-categoryButton',
                    {
                      active:
                        selectedCategory &&
                        node.data.identifier ===
                          selectedCategory.data.identifier
                    }
                  )}
                  key={node.data.identifier}
                  value={node.data.identifier}
                  onClick={this.selectTopLevelDomain}
                >
                  {node.data.name}
                  {selectedSkills > 0 && ` (${selectedSkills})`}
                </button>
              );
            })}
          </div>

          {selectedCategory &&
            selectedCategory.children!.map((node) => (
              <SecondaryDomain
                key={node.data.identifier}
                domain={node}
                selectedTertiaryDomainIDs={selectedTertiaryDomainIDs}
                toggleSecondaryDomain={this.toggleSecondaryDomain}
                toggleTertiaryDomain={this.toggleTertiaryDomain}
              />
            ))}
        </div>
      </>
    );
  }
}
