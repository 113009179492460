import './TermsPartitionGraph.scss';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { navigate } from 'gatsby';
import * as d3 from 'd3';

import { Framework } from 'types';
import { Size } from 'utils/geometry';

import SelectionDetails from './SelectionDetails';

import data from 'data/compare-terms.json';

interface Props {
  frameworks: Framework[];
  selectedSkillIDs: string[];
}

interface NodeData {
  identifier: string;
  parentNode: string | null;
  name: string;
  frameworkID: number;
  skills?: string[];
}

const size = new Size(600, 600);

const termsInFrameworks = data.children.reduce(
  (counts, framework) => ({
    ...counts,
    [framework.identifier]: d3.hierarchy(framework).leaves().length
  }),
  {} as { [index: string]: number }
);

// Logic for selecting terms is disabled, but largely still present as it seems
// possible we'll add it back in some form. If not it should be removed.

/**
 * Render a big wheel diagram of all the terms in all frameworks. When hovering
 * over a term, related terms are highlighted and details about that term are
 * displayed.
 */
const TermsPartitionGraph: React.FC<Props> = (props) => {
  const { frameworks, selectedSkillIDs } = props;

  const frameworkWithID = useCallback((id: number) => {
    return data.children.find((f) => f.identifier === id)!;
  }, []);

  const visibleFrameworkIDs = useMemo(
    () => frameworks.map((framework) => framework.identifier),
    [frameworks]
  );

  const svgSelection =
    useRef<d3.Selection<SVGSVGElement | null, any, null, undefined>>();
  const rootGroupSelection =
    useRef<d3.Selection<SVGGElement, any, null, undefined>>();

  const svgNode = useRef<SVGSVGElement | null>(null);

  const [hoveredItem, setHoveredItem] = useState<NodeData | null>(null);
  const [selectedItem] = useState<NodeData | null>(null);

  // Setup Graph

  useEffect(() => {
    svgSelection.current = d3.select(svgNode.current);
    svgSelection.current.selectAll('g').remove();
    rootGroupSelection.current = svgSelection.current.append('g');
  }, []);

  // Update Graph

  useEffect(() => {
    if (rootGroupSelection.current == null) {
      return;
    }

    rootGroupSelection.current.selectAll('.term-node').remove();

    // TODO: Optimize this. Some logic doesn't need to be re-run every time the
    // view is rendered.
    const radius = Math.min(size.width, size.height) / 2.0;

    const root = {
      ...data,
      children: data.children
        .filter((f) => visibleFrameworkIDs.includes(f.identifier))
        .filter((f) => f.identifier === f.group)
    };

    const treeData = d3
      .hierarchy(root)
      .sum((d) => (d.children ? 0 : 1.0 / termsInFrameworks[d.frameworkID!]));

    const partitionLayout = d3.partition<NodeData>()(treeData as any);

    const rScale = d3.scaleLinear().range([radius * 0.25, radius]);
    const aScale = d3.scaleLinear().range([0, 2 * Math.PI]);

    const arc = d3
      .arc<any, d3.HierarchyRectangularNode<NodeData>>()
      .startAngle((d) => aScale(d.x0))
      .endAngle((d) => aScale(d.x1))
      .innerRadius((d) => rScale(d.y0))
      .outerRadius((d) => rScale(d.y1));

    rootGroupSelection.current.attr(
      'transform',
      `translate(${size.width / 2.0},${size.height / 2.0})`
    );

    const nodeGroups = rootGroupSelection.current
      .selectAll('.term-node')
      .data(partitionLayout.descendants().slice(1))
      .enter()
      .append('g')
      .attr('class', 'term-node');

    const path = nodeGroups
      .append('path')
      .attr('class', 'term-path')
      .attr('d', arc)
      .style('fill', (d) => {
        const ancestors = d.ancestors();
        const framework = ancestors[ancestors.length - 2].data;
        return frameworkWithID(framework.frameworkID).color;
      });

    path
      .on('mouseenter', (_event: any, d: any) => {
        setHoveredItem(d.data);
      })
      .on('mouseleave', () => {
        setHoveredItem(null);
      })
      .on('click', (_event: any, d: d3.HierarchyRectangularNode<NodeData>) => {
        navigate(
          d.depth === 1
            ? `/frameworks/${d.data.identifier}`
            : `/terms/${d.data.identifier}`
        );
      });
  }, [frameworkWithID, visibleFrameworkIDs]);

  // Update segment highlighting

  useEffect(() => {
    const paths = d3.selectAll('.term-node');

    paths.classed('highlighted', (d: any) => {
      if (selectedSkillIDs.length > 0) {
        return (
          d.data.skills &&
          d.data.skills.some((s: any) => selectedSkillIDs.includes(s))
        );
      } else if (hoveredItem != null) {
        return (
          d.data.skills &&
          hoveredItem.skills &&
          d.data.skills.some((s: any) => hoveredItem.skills?.includes(s))
        );
      } else {
        return true;
      }
    });

    paths.classed('focused', (d: any) => d.data === hoveredItem);
    paths.classed('selected', (d: any) => d.data === selectedItem);
  }, [hoveredItem, selectedItem, selectedSkillIDs, visibleFrameworkIDs]);

  return (
    <div className="TermsPartitionGraph" aria-live="polite">
      <svg
        className="TermsPartitionGraph-svg"
        viewBox={`0 0 ${size.width} ${size.height}`}
        ref={svgNode}
        role="img"
        aria-label="Compare terms visualization"
      />

      <SelectionDetails
        frameworks={frameworks}
        hoveredItem={hoveredItem !== selectedItem ? hoveredItem : null}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default TermsPartitionGraph;
