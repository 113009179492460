import './CompareTermsVisualization.scss';

import React from 'react';

import { Framework } from 'types';

import { usePersistentState } from 'utils/usePersistentState';

import VisualToolControls from 'components/VisualToolControls';
import { useFrameworkFilters, NoMatches } from 'components/framework-filters';

import TermsPartitionGraph from './TermsPartitionGraph';
import DomainSelector from './domain-selector/DomainSelector';
import PrintableDomains from './domain-selector/Printable';

interface Props {
  frameworks: Framework[];
}

/**
 * Renders the 'compare terms' visualization which is a circular diagram
 * including the structure of terms for each framework.
 *
 * Alongside the visualization is a nested set of all the 'skills' which can
 * be turned on and off. The corresponding terms in with the selected skills
 * are highlighted in the visualization.
 */
const CompareTermsVisualization: React.FC<Props> = (props) => {
  const { frameworks } = props;

  const [selectedTertiaryDomainIDs, setSelectedTertiaryDomainIDs] =
    usePersistentState<string[]>('compare-terms-selectedTertiaryDomainIDs', []);

  const filters = useFrameworkFilters(frameworks);
  const filteredFrameworks = filters.frameworks;

  return (
    <div className="CompareTermsVisualization">
      <div className="CompareTermsVisualization-filters">
        <VisualToolControls filters={filters} />
      </div>

      <PrintableDomains selectedTertiaryDomainIDs={selectedTertiaryDomainIDs} />

      <div className="CompareTermsVisualization-container">
        <div className="CompareTermsVisualization-options no-print">
          <DomainSelector
            selectedTertiaryDomainIDs={selectedTertiaryDomainIDs}
            setSelectedTertiaryDomainIDs={setSelectedTertiaryDomainIDs}
          />

          <p>
            Each piece of the sunburst represents a skill within a framework.
            The farther you move out from the center of the sunburst, the more
            discrete and specific the skills become.
          </p>
        </div>
        <div className="CompareTermsVisualization-visualization">
          {filteredFrameworks.length === 0 ? (
            <NoMatches resetFilters={filters.reset} />
          ) : (
            <TermsPartitionGraph
              selectedSkillIDs={selectedTertiaryDomainIDs}
              frameworks={filteredFrameworks}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompareTermsVisualization;
